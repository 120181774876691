/**
 * A helper function to parse a JSON string safely
 *
 * @param {string} str - The string to parse
 * @returns {string | object} - The parsed JSON object or the string
 */
function parseToJSONSafe(str: string): string | { [key: string]: unknown } {
  try {
    return JSON.parse(str);
  } catch (error) {
    console.error('Unable to parse string to JSON: ', str);
  }
  return str;
}

export default parseToJSONSafe;
